import { Transition } from '@headlessui/react';

const AppHeader = ({
  user,
  subscription,
  hasMoreRenders,
  setOpenHelper,
  setOpenProHelper,
  uploading,
  currentRenders
}) => {
  if (!user && !subscription) {
    return <span></span>;
  } else {
    return (
      <div className="pt-1">
        {/* <Transition
              show={uploading !== 'uploading' && currentRenders?.length == 1}
              enter="duration-500 ease-out"
              enterFrom="opacity-100 -mt-8"
              enterTo="opacity-100"
              className={'w-full'}
            >
              <div className="text-center">
                <h1 className="text-xl font-extrabold text-slate-700 text-center mt-5 sm:mt-10 sm:text-2xl m-0 leading-none">
                  Advanced Edit Mode
                </h1>
              </div>
            </Transition> */}
        <Transition
          show={uploading !== 'uploading' && currentRenders?.length > 1}
          enter="duration-500 ease-out"
          enterFrom="opacity-100 -mt-8"
          enterTo="opacity-100"
          // leave="duration-500 ease-in"
          // leaveFrom="opacity-100 scale-100"
          // leaveTo="opacity-0 scale-95"
          className={'w-full'}
        >
          <div className="text-center">
            <h1 className="text-xl font-extrabold text-slate-700 text-center mt-5 sm:mt-10 sm:text-2xl m-0 leading-none">
              Transformation Complete
            </h1>
            <div className="text-pink-500 font-bold text-sm m-0 mt-1 text-center">
              Enjoy your renders below
            </div>
            {/* <a
                onClick={() => setOpenHelper('true')}
                className="underline text-indigo-500 font-bold text-xs m-0 text-center"
              >
                Go back to try another style or 
              </a> */}
          </div>
        </Transition>

        {uploading === 'uploading' && (
          <div className="text-center w-full">
            <h1 className="text-sm font-extrabold text-slate-900 text-center mt-5 sm:mt-10 sm:text-2xl m-0 leading-none">
              Hold Tight...
            </h1>
            <div className="text-sm m-0 mt-1 text-center font-bold">
              <span className="text-[#4138C2] text-base">Snapluna AI</span> is
              redesigning your Model
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default AppHeader;
